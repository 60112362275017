import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getCertificateDetailsAsync } from '../../../Services/CertificateService';
import jsPDF from 'jspdf';
import CPD from '../../../Assets/CPD.png';
import SustainabiltyAcademyLogo from '../../../Assets/The Sustanability Academy LOGO.png';
import moment from 'moment';
import TSALogo from '../../../Assets/TSALogo.png';
import Signature from '../../../Assets/signature.png';

const Certificate = () => {

    const { userId, courseId } = useParams();

    useEffect(() => {
        print();
    }, [])

    const print = async () => {
        let response = await getCertificateDetailsAsync(userId, courseId);
        let title = response.course.title;
        let completedDateTime = moment(response.course.completedDateTime).format("DD-MM-YYYY");
        let learner = response.learner;

        // let title = "My course title"
        // let completedDateTime = moment().format("DD-MM-YYYY");
        // let learner = {
        //     name: "John Smith"
        // };

        const pdf = new jsPDF("p", "mm", "a4");
        let width = pdf.internal.pageSize.getWidth() / 2;
        pdf.addImage(TSALogo, 'JPEG', 20, 20, 130, 40);

        pdf.setTextColor(60, 60, 60);
        pdf.setFontSize(22);
        pdf.text('CERTIFICATE OF COMPLETION', 20, 90, 'left');

        pdf.setFontSize(18);
        pdf.text('This is to certify that', 20, 108, 'left');

        pdf.setFontSize(23);
        pdf.setTextColor(60, 60, 60);
        let splitLearner = pdf.splitTextToSize(learner.name, 150);
        pdf.text(20, 128, splitLearner, 'left');

        pdf.setFontSize(18);
        pdf.setTextColor(113, 113, 113);
        pdf.text('has successfully completed', 20, 145, 'left')

        pdf.setFontSize(25);
        pdf.setTextColor(60, 60, 60);
        let splitCourseTitle = pdf.splitTextToSize(title, 150);
        pdf.text(20, 168, splitCourseTitle, 'left');

        pdf.addImage(TSALogo, 'JPEG', 25, 214, 60, 20);
        pdf.addImage(Signature, 'JPEG', 30, 234, 50, 16);

        pdf.setFontSize(12);

        pdf.save(`${learner.name} ${title}.pdf`);
    };

    return (
        <div className="container-fluid content">
            <div className="row p-5 mt-5">
                <div className="col-12 text-center p-5">
                    <div className="spinner-border mb-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <h1>Please wait while your certificate downloads</h1>
                </div>
            </div>
        </div>
    );
};

export default Certificate;