import React, { useEffect, useState } from 'react';
import { getCourseFeedbackAsync } from '../../../Services/FeedbackFormService';
import Card from '../../../Shared/Components/Card/Card';
import Header from '../../../Shared/Components/Header/Header';
import { useParams } from 'react-router-dom';

const FeedbackStats = () => {
    const [feedbackForm, setFeedbackForm] = useState({
        questions: [],
        additionalFeedback: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const { id, clientId } = useParams();

    useEffect(() => {
        const getForm = async () => {
            try {
                const response = await getCourseFeedbackAsync(id, clientId);
                setFeedbackForm(response);
            } catch (error) {
                console.error("Error fetching feedback:", error);
            } finally {
                setIsLoading(false);
            }
        };

        getForm();
    }, [id, clientId]);

    if (isLoading) {
        return <div className="container-fluid content">Loading...</div>;
    }

    return (
        <>
            <Header heading="Feedback Statistics" subheading="Course Feedback" icon="book" />
            <div className="container-fluid content">
                {feedbackForm.questions && feedbackForm.questions.length > 0 ? (
                    feedbackForm.questions.map((question, index) => (
                        <Card key={index} title={question.value}>
                            {question.answers && question.answers.length > 0 ? (
                                <ul className="list-group">
                                    {question.answers.map((answer, answerIndex) => (
                                        <li key={answerIndex} className="list-group-item">
                                            {answer.value}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No answers provided for this question.</p>
                            )}
                        </Card>
                    ))
                ) : (
                    <Card title="No Questions">
                        <p>There are no questions available for this feedback.</p>
                    </Card>
                )}

                {feedbackForm.additionalFeedback && feedbackForm.additionalFeedback.length > 0 && (
                    <Card title="Additional Feedback">
                        <ul className="list-group">
                            {feedbackForm.additionalFeedback.map((feedback, index) => (
                                feedback.trim() && (
                                    <li key={index} className="list-group-item">
                                        {feedback}
                                    </li>
                                )
                            ))}
                        </ul>
                    </Card>
                )}

                {(!feedbackForm.questions || feedbackForm.questions.length === 0) &&
                    (!feedbackForm.additionalFeedback || feedbackForm.additionalFeedback.length === 0) && (
                        <Card title="No Feedback">
                            <p>There is no feedback available for this course.</p>
                        </Card>
                    )}
            </div>
        </>
    );
};

export default FeedbackStats;
